import Swiper, {Navigation, Pagination} from 'swiper/core';

Swiper.use([Navigation, Pagination]);

if (document.querySelector('.article-block')) {
    if (document.querySelector('.swiper-article')) {
        document.querySelector(`.swiper-article-navigation[data-article="${document.querySelector('.js-tabs__tab.active').dataset.article}"]`).classList.add('active');
        document.querySelectorAll('.js-tabs__tab').forEach(item => {
            item.addEventListener('click', function () {
                document.querySelectorAll('.swiper-article-navigation').forEach(i => i.classList.remove('active'));
                document.querySelector(`.swiper-article-navigation[data-article="${item.dataset.article}"]`).classList.add('active');
            });
        });
        document.querySelectorAll('.swiper-article').forEach(item => {
            let currentArticle = item.closest('.js-tabs__content').dataset.article;
            let currentNavigation = document.querySelector(`.swiper-article-navigation[data-article="${currentArticle}"]`);

            let navigation = {};
            if (currentArticle) {
                let next = currentNavigation.querySelector('.swiper-article-next');
                let prev = currentNavigation.querySelector('.swiper-article-prev');
                navigation = {
                    nextEl: next,
                    prevEl: prev,
                };
            }
            new Swiper(item, {
                spaceBetween: 10,
                navigation: navigation,
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                watchSlidesVisibility: true,
                slideActiveClass: "active",
                breakpoints: {
                    1524: {
                        slidesPerView: 'auto',
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    }
                }
            });
        });
    }
}

if (document.querySelector('.reviews-slider')) {
    new Swiper('.reviews-slider', {
        spaceBetween: 40,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-reviews-next',
            prevEl: '.swiper-reviews-prev',
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
            }
        }
    });
}

document.querySelectorAll('.procedure-swiper').forEach(item => {
    new Swiper(item, {
        spaceBetween: 10,
        slidesPerView: 1,
        navigation: {
            nextEl: '.procedure-swiper-next',
            prevEl: '.procedure-swiper-prev'
        },
        pagination: {
            el: '.procedure-swiper-pagination',
            type: 'bullets',
            clickable: true,
        }
    });
});
document.querySelectorAll('.procedure-gallery-swiper').forEach(item => {
    new Swiper(item, {
        spaceBetween: 10,
        slidesPerView: 1,
        navigation: {
            nextEl: '.procedure-gallery-swiper-next',
            prevEl: '.procedure-gallery-swiper-prev'
        },
        pagination: {
            el: '.procedure-swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 3,
            }
        }
    });
});